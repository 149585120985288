import React, { useState, Fragment } from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Launch from './Pages/Launch/Launch';
import Home from './Pages/home/home';
import HomeModule from './Pages/home2/home';
import ReactModule from './Projects/react/react';
import AngularModule from './Projects/angular/angular';
import VueModule from './Projects/vue/vue';
import NodeModule from './Projects/node/node';
import NativeModule from './Projects/native/native';
import FlaskModule from './Projects/flask/flask';
import SpringModule from './Projects/spring/spring';
import LaravelModule from './Projects/laravel/laravel';
import ASPModule from './Projects/ASP/ASP';
import ScssModule from './Projects/Scss/Scss';
import GolangModule from './Projects/Golang/Golang';
import NestModule from './Projects/Nest/Nest';
import NextModule from './Projects/Next/Next';
import NuxtModule from './Projects/Nuxt/Nuxt';
import DjangoModule from './Projects/django/django';
import FastAPIModule from './Projects/fastapi/fastapi';
import OpenCVModule from './Projects/OpenCV/OpenCV';
import MainModule from './Pages/main/main';

const App = () => {
	const [
		status,
		setStatus
	] = useState('');

	const onStart = () => {
		setTimeout(() => {
			setStatus('start');
			sessionStorage.setItem('Begin', 'true');
		}, 8500);
	};

	const Begin = sessionStorage.getItem('Begin');

	return (
		<div className="container">
			<Router>
				<Switch>
					{/* <Route exact path="/" component={MainModule} /> */}
					<Route exact path="/" component={HomeModule} />

					<Route
						exact
						path="/Rocket"
						render={(props) => (
							<Fragment>
								{!Begin ? status === 'start' ? <Home /> : <Launch onStart={onStart} /> : <Home />}
							</Fragment>
						)}
					/>
					<Route exact path="/React" component={ReactModule} />
					<Route exact path="/OpenCV" component={OpenCVModule} />
					<Route exact path="/FastAPI" component={FastAPIModule} />
					<Route exact path="/Angular" component={AngularModule} />
					<Route exact path="/Vue" component={VueModule} />
					<Route exact path="/Node" component={NodeModule} />
					<Route exact path="/Native" component={NativeModule} />
					<Route exact path="/Flask" component={FlaskModule} />
					<Route exact path="/Spring" component={SpringModule} />
					<Route exact path="/Laravel" component={LaravelModule} />
					{/* <Route exact path="/home" component={HomeModule} /> */}
					<Route exact path="/ASP" component={ASPModule} />
					<Route exact path="/Scss" component={ScssModule} />
					<Route exact path="/Go" component={GolangModule} />
					<Route exact path="/Nest" component={NestModule} />
					<Route exact path="/Next" component={NextModule} />
					<Route exact path="/Nuxt" component={NuxtModule} />
					<Route exact path="/Django" component={DjangoModule} />
				</Switch>
			</Router>
		</div>
	);
};

export default App;
