import React, { useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
// import styles from './home.module.scss';
import NavComponent from '../../Components/NavComponent/NavComponent';
import FooterModule from '../../Components/footer/footer';
// import { Link } from 'react-router-dom';
import { viewTransport } from '../../helper';
import { right } from '@popperjs/core';
import { Link } from 'react-router-dom';
import { AiOutlineHome, AiFillPhone, AiFillGithub, AiOutlineMail } from 'react-icons/ai';
import { SiFiverr } from 'react-icons/si';

let styles = require('./home.module.scss');

const Home = () => {
	// const [
	// 	status,
	// 	setStatus
	// ] = useState('');

	useEffect(() => {
		viewTransport();

		// setTimeout(() => {
		// 	setStatus('stop');
		// }, 3000);
	});

	window.onscroll = function () {
		viewTransport();
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<div>
			<div id={styles.test}>
				<h4 className={styles.head} id="top" style={{ marginTop: '30px', marginBottom: '40px' }}>
					Tenzin
					<br />
					{/* <br /> */}
					<div style={{ fontSize: '50px', marginTop: '40px' }}> "^_^"</div>
				</h4>

				<div
					className={styles.contactLinks}
					style={{
						// float: right,
						position: 'absolute',
						top: 0,
						left: 0,
						marginTop: '5%',
						marginLeft: '20%'
						// height: '400px',
					}}>
					{/* <span style={{ fontSize: '13px' }}>
						<AiOutlineHome /> &nbsp; Straßburger Str. 24, 70435 Stuttgart
					</span> */}
					{/* <br />
					<br />

					<span>
						<AiFillPhone /> &nbsp; +49-1636251855
					</span> */}
					<br />
					<br />

					<span style={{ fontSize: '13px' }}>
						<AiFillGithub />&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
						<a className={styles.contactLink} href="https://github.com/gyurmey" target="_blank">
							<i>gyurmey</i>
						</a>
					</span>
					<br />
					<br />

					<span style={{ fontSize: '13px' }}>
						<AiOutlineMail />&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
						<a
							className={styles.contactLink}
							href="mailto:gyurmey1835@gmail.com?subject=Hi from Portfolio page">
							<i>gyurmey1835@gmail.com</i>
						</a>
					</span>

					<br />
					<br />

					<span>
						<span style={{ fontSize: '23px' }}>
							<SiFiverr />
						</span>&nbsp; &nbsp;&nbsp; &nbsp;
						<span style={{ fontSize: '13px' }}>
							<a
								className={styles.contactLink}
								href="https://www.fiverr.com/tenzingyurmey?public_mode=true"
								target="_blank">
								<i>tenzingyurmey</i>
							</a>
						</span>
					</span>
				</div>

				<div
					style={{
						// float: right,
						position: 'absolute',
						top: 0,
						right: 0,
						marginTop: '1%',
						marginRight: '20%'
						// height: '400px',
					}}>
					<a
						className={styles.anchorLink}
						style={{ textDecoration: 'none' }}
						href="https://tenzin-intro.netlify.app/"
						target="_blank"
						rel="noreferrer">
						<b style={{ textDecoration: 'none', fontSize: '12px' }}>
							<Button
								className={styles.linkBtn}
								style={{ width: '150px', height: '38px' }}
								variant="dark">
								Short Bio
							</Button>{' '}
						</b>
					</a>
					<br />
					<br />
					{/* <a
						className={styles.anchorLink}
						style={{ textDecoration: 'none' }}
						href="https://keen-mccarthy-eebcd3.netlify.app/"
						target="_blank"
						rel="noreferrer">
						<b style={{ textDecoration: 'none', fontSize: '12px' }}>
							<Button
								className={styles.linkBtn}
								style={{ width: '150px', height: '38px' }}
								variant="dark">
								My Blog
							</Button>
						</b>
					</a>{' '}
					<br />
					<br />{' '} */}
					{/* <a
						className={styles.anchorLink}
						style={{ textDecoration: 'none' }}
						href="https://www.npmjs.com/~gyurmey"
						target="_blank"
						rel="noreferrer">
						<b style={{ textDecoration: 'none', fontSize: '12px' }}>
							<Button
								className={styles.linkBtn}
								style={{ width: '150px', height: '38px' }}
								variant="dark">
								Open source
							</Button>
						</b>
					</a>
					<br />
					<br />{' '} */}
					{/* <div className={styles.wes}>
						<div
							data-iframe-width="150"
							data-iframe-height="270"
							data-share-badge-id="5131d89d-effa-4a68-b0a3-257488869277"
							data-share-badge-host="https://www.credly.com"
						/>
					</div> */}
					{/* <Link
						className={styles.anchorLink}
						style={{ textDecoration: 'none' }}
						// href="https://www.npmjs.com/~gyurmey"
						to="Rocket"
						target="_blank"
						rel="noreferrer">
						<b style={{ textDecoration: 'none', fontSize: '12px' }}>
							<Button
								className={styles.linkBtn}
								style={{ width: '150px', height: '38px' }}
								variant="dark">
								Portfolio
							</Button>
						</b>
					</Link> */}
					<br />
					<br />{' '}
					{/* <a
						className={styles.anchorLink}
						style={{ textDecoration: 'none' }}
						href="https://checkboxolympics.com/"
						target="_blank"
						rel="noreferrer">
						<b style={{ textDecoration: 'none', fontSize: '12px' }}>
							<Button
								className={styles.linkBtn}
								style={{ width: '150px', height: '38px' }}
								variant="dark">
								Game
							</Button>{' '}
						</b>
					</a> */}
				</div>
				<div className={styles.content}>
					<br />

					<span className={styles.subLinks}>
						{/* <a href="https://tenzin-intro.netlify.app/" target="_blank" rel="noreferrer">
							<b>Short Bio</b>
						</a>{' '} */}
						{/* | &nbsp;
						<a href="https://keen-mccarthy-eebcd3.netlify.app/" target="_blank" rel="noreferrer">
							<b>My Blog</b>
						</a>{' '}
						| &nbsp;
						<a href="https://www.npmjs.com/~gyurmey" target="_blank" rel="noreferrer">
							<b>Open source</b>
						</a>{' '} */}
						{/* | &nbsp; */}
						{/* <OverlayTrigger
							overlay={
								<Tooltip id="challenge">
									Challenge me in this Game, My Record without using mouse and only mousepad is{' '}
									<strong>20seconds. </strong>You think you can beat me on this. ^^
								</Tooltip>
							}>
							<a href="https://checkboxolympics.com/" target="_blank" rel="noreferrer">
								<b>Online Game </b>
							</a>
						</OverlayTrigger>{' '} */}
						{/* | &nbsp;
						<a href="https://www.npmjs.com/~gyurmey" target="_blank" rel="noreferrer">
							<b>Resume</b>
						</a>{' '} */}
						{/* <a href="" target="_blank" rel="noreferrer">
							<b>Play audio</b>
						</a>{' '}
						| &nbsp; */}
					</span>
					<br />
					<br />
					<div className={styles.intro}>
						Hey there! My name is Tenzin Gyurmey. In Tibetan, my name is བསྟན་འཛིན་ འགྱུར་མེད། . I am a
						Software Engineer, currently based in Germany. I mostly specialize in Typescript and Javascript,
						but also write Python, C, C++. &nbsp;
						{/* Go, C#,  */}
						{/* PHP etc. &nbsp; */}
						{/* I have working experience as a full-stack but i am more inclined
						to frontend as building state of art user friendly Web and Native App focusing on user interface
						and user experience with modern framework is a passion of mine.  */}
						i am also a keen Open source contributor 👻 &nbsp; but so far, haven't fought in large arena.
						So, invite me if you have some very interesting Project like the Mars Rover ^^, so we can
						collaborate.
						{/* You can contact me at{' '}
						<a href="mailto:gyurmey1835@gmail.com?subject=Hi from Portfolio page">
							<i>gyurmey1835@gmail.com</i>
						</a> */}
						{/* . Here are some of my small side projects. Click on the respective button to view its projects.
						ENJOY ! ^^ */}
					</div>
					{/* <NavComponent /> */}

					<div className={styles.breaks}>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>
					<FooterModule />
				</div>
				<br />
				<br />
				<br />
				<Button variant="light" onClick={scrollToTop} id="scrollToTop" className={styles.scrollTopBtn}>
					<i className="bi bi-arrow-up-circle" />
					<br />
					Top
				</Button>
			</div>
		</div>
	);
};

export default Home;
