import registration from './../../image/registration.png';

const ScssDatas = [{
    name: registration,
    title: 'Registration page',
    status: 'Complete',
    text: 'A Simple validation page built without using any library or framework and used scss',
    demo: 'https://musing-wilson-e9a609.netlify.app/',
    github: 'https://github.com/gyurmey/registration-scss'
}];

export default ScssDatas;