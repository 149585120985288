import React, { Fragment, useState, useEffect } from 'react';
import '../../App.scss';
// import styles from './Launch.module.scss';
import Rocket2 from './../../image/rocket2.gif';
import Rocket22 from './../../image/rocket2-0.png';

let styles = require('./Launch.module.scss');
// let Rocket2 = require('./../../image/rocket2.gif');
// let Rocket22 = require('./../../image/rocket2-0.png');

const Main = (props: { onStart: (arg0: string) => void }) => {
	const [
		status,
		setStatus
	] = useState('');

	const [
		buttonStatus,
		setButtonStatus
	] = useState(true);

	const change = () => {
		setStatus('start');
		props.onStart(status);
	};

	useEffect(() => {
		setTimeout(() => {
			setButtonStatus(false);
		}, 2000);
	});

	return (
		<Fragment>
			<img src={Rocket2} alt="rocket" className={styles.center} />{' '}
			{status === 'start' ? (
				<div className={styles.main}>
					<img src={Rocket2} alt="rocket" className={styles.center} />
				</div>
			) : (
				<div className={styles.pre}>
					<img src={Rocket22} alt="rocket" className={styles.center} />
					<button disabled={buttonStatus} onClick={change} className={styles.launch}>
						launch
					</button>
				</div>
			)}
		</Fragment>
	);
};

export default Main;
