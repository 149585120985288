const navDatas = [{
        link: 'React',
        name: 'React'
    },
    {
        link: 'Angular',
        name: 'Angular'
    },
    {
        link: 'Vue',
        name: 'Vue.js'
    },
    {
        link: 'Node',
        name: 'Node.js'
    },
    {
        link: 'Native',
        name: 'Native'
    },
    {
        link: 'Scss',
        name: 'Scss'
    },
    {
        link: 'Django',
        name: 'Django'
    },
    {
        link: 'Flask',
        name: 'Flask'
    },
    {
        link: 'FastAPI',
        name: 'FastAPI'
    },
    {
        link: 'Laravel',
        name: 'Laravel'
    },
    {
        link: 'Spring',
        name: 'Spring Boot'
    },
    {
        link: 'ASP',
        name: 'ASP.NET'
    },

    {
        link: 'Go',
        name: 'Golang'
    },
    {
        link: 'Next',
        name: 'Next'
    },
    {
        link: 'Nuxt',
        name: 'Nuxt'
    },
    {
        link: 'Nest',
        name: 'Nest'
    },
    {
        link: 'OpenCV',
        name: 'OpenCV'
    }
    //    {
    //     link: 'Tensorflow',
    //     name: 'Tensorflow'
    // },
    //    {
    //     link: 'Pytorch',
    //     name: 'Pytorch'
    // }
];

export default navDatas;