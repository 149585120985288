import domain from './../../image/domain.png';
import weather from './../../image/weather.png';
import production from './../../image/production.png';
import stocks from './../../image/stocks.png';
import quiz from './../../image/quiz.png';
import blog from './../../image/blog.png';

const VueDatas = [{
        name: domain,
        title: 'Companies Domain Administrator',
        status: 'Complete',
        text: 'A Vue app to administer companies domain build with Vuex.',
        demo: 'https://fervent-mayer-c96f62.netlify.app/',
        github: 'https://github.com/gyurmey/P-companies-domain-administrator'
    },
    {
        name: production,
        title: 'Production Dashboard',
        status: 'WIP',
        text: 'A Vue app to administer products status built using Vue material.',
        demo: 'https://cocky-bhabha-9c11df.netlify.app/',
        github: 'https://github.com/gyurmey/P-Production-dashboard'
    },
    {
        name: stocks,
        title: 'Stock Market',
        status: 'WIP',
        text: 'A Vue app to display real time stocks prices and changes.',
        demo: 'https://elated-morse-c722ae.netlify.app/',
        github: 'https://github.com/gyurmey/P-Stock-dashboard'
    },
    {
        name: weather,
        title: 'Weather App',
        status: 'Complete',
        text: 'A Vue app to display weather information.',
        demo: '',
        github: 'https://github.com/gyurmey/P-weather-app'
    },
    {
        name: quiz,
        title: 'Quiz App',
        status: 'Complete',
        text: 'A Short Quiz app built with Vue2 and vuex',
        demo: 'https://hopeful-curie-bb55e8.netlify.app/#/',
        github: 'https://github.com/gyurmey/Quiz'
    },
    {
        name: blog,
        title: 'Blog Post',
        status: 'Complete',
        text: 'A simple blog post site built with Vue3 and composition API store',
        demo: 'https://blissful-aryabhata-c8401a.netlify.app/#/',
        github: 'https://github.com/gyurmey/composition-api2'
    }
];

export default VueDatas;