import React from 'react';
import footer from './../../image/favicon.jpg';
// import styles from './footer.module.scss';

let styles = require('./footer.module.scss');

// let footer = require('./../../image/favicon.jpg');

function Footer() {
	return (
		<div className={styles.footerMain}>
			<span className={styles.footerLine}>
				<hr />
			</span>
			<br />
			<span className="d-flex justify-content-center ">
				<span className={styles.footer}>
					<img src={footer} alt="footer" className={styles.img} />
				</span>
			</span>
		</div>
	);
}

export default Footer;
