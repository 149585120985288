// import styles from './flask.module.scss';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import FooterModule from '../../Components/footer/footer';
import { Button as Btn } from 'tenzin-ui-btn';
import styled from 'styled-components';
import { viewTransport } from '../../helper';

let styles = require('./fastapi.module.scss');

const IconButton = styled(Btn)`
margin-top:0px;
@media (max-width: 700px){
	position: absolute;
	top: 0;
}
`;

const FastAPI = () => {
	useEffect(() => {
		viewTransport();
	});

	window.onscroll = function() {
		viewTransport();
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<div className={styles.main}>
			<span className={styles.title}>
				{/* <i className={`bi bi-arrow-left ${styles.icon}`} /> */}
				<Link to="/Rocket">
					<IconButton>Back</IconButton>{' '}
				</Link>
			</span>

			<br />
			<br />
			<br />
			<h1 className={styles.heading}>
				{' '}
				FastAPI
				<div>FastAPI</div>
				<div>FastAPI</div>
				<div>FastAPI</div>
				<div>FastAPI</div>
			</h1>

			<h4 className={styles.instruct}>Click on the Picture!</h4>
			<br />

			<div className={styles.comingSoon}>Any Suggestions?..</div>
			<br />
			<FooterModule />
			<br />
			<br />
			<br />
			<Button variant="light" onClick={scrollToTop} id="scrollToTop" className={styles.scrollTopBtn}>
				<i className="bi bi-arrow-up-circle" />
				<br />
				Top
			</Button>
		</div>
	);
};

export default FastAPI;
