import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Typist from 'react-typist';
// import styles from './home.module.scss';
import NavComponent from '../../Components/NavComponent/NavComponent';
import FooterModule from '../../Components/footer/footer';
import { viewTransport } from '../../helper';

let styles = require('./home.module.scss');

const Home = () => {
	const [
		status,
		setStatus
	] = useState('');

	useEffect(() => {
		viewTransport();

		setTimeout(() => {
			setStatus('stop');
		}, 3000);
	});

	window.onscroll = function() {
		viewTransport();
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<div style={{ marginTop: '200px' }}>
			{/* <div id={styles.test}> */}
			{/* <h4 className={styles.head} id="top">
				Tenzin
			</h4> */}
			{/* {status !== 'stop' && <Spinner animation="border" className={styles.spinner} />} */}

			<NavComponent />
			<br />
			<br />
			<br />
			<Button variant="light" onClick={scrollToTop} id="scrollToTop" className={styles.scrollTopBtn}>
				<i className="bi bi-arrow-up-circle" />
				<br />
				Top
			</Button>
			{/* </div> */}
		</div>
	);
};

export default Home;

// <div className={styles.content}>
// <br />

// <span className={styles.subLinks}>
// 	<a href="https://tenzin-intro.netlify.app/" target="_blank" rel="noreferrer">
// 		<b>Short Bio</b>
// 	</a>{' '}
// 	{/* | &nbsp;
// 	<a href="https://keen-mccarthy-eebcd3.netlify.app/" target="_blank" rel="noreferrer">
// 		<b>My Blog</b>
// 	</a>{' '}
// 	| &nbsp;
// 	<a href="https://www.npmjs.com/~gyurmey" target="_blank" rel="noreferrer">
// 		<b>Open source</b>
// 	</a>{' '} */}
// 	| &nbsp;
// 	<OverlayTrigger
// 		overlay={
// 			<Tooltip id="challenge">
// 				Challenge me in this Game, My Record without using mouse and only mousepad is{' '}
// 				<strong>20seconds. </strong>You think you can beat me on this. ^^
// 			</Tooltip>
// 		}>
// 		<a href="https://checkboxolympics.com/" target="_blank" rel="noreferrer">
// 			<b>Online Game </b>
// 		</a>
// 	</OverlayTrigger>{' '}
// 	{/* | &nbsp;
// 	<a href="https://www.npmjs.com/~gyurmey" target="_blank" rel="noreferrer">
// 		<b>Resume</b>
// 	</a>{' '} */}
// 	{/* <a href="" target="_blank" rel="noreferrer">
// 		<b>Play audio</b>
// 	</a>{' '}
// 	| &nbsp; */}
// </span>
// <br />
// <br />
// <div className={styles.intro}>
// 	<Typist>
// 		Hey there! My name is Tenzin Gyurmey. In Tibetan, my name is བསྟན་འཛིན་ འགྱུར་མེད། . I am a
// 		Software Engineer, currently based in Germany. I mostly specialize in Typescript and
// 		Javascript, but also write Python, C, C++, Go, C#, PHP etc. &nbsp;
// 		{/* I have working experience as a full-stack but i
// 		am more inclined to frontend as building state of art user friendly Web and Native App
// 		focusing on user interface and user experience with modern framework is a passion of mine.  */}
// 		i am also a keen Open source contributor 👻 &nbsp; but so far, haven't fought in large
// 		arena. So, invite me if you have some very interesting Project like the Mars Rover ^^, so we
// 		can collaborate. You can contact me at{' '}
// 		<a href="mailto:gyurmey1835@gmail.com?subject=Hi from Portfolio page">
// 			<i>gyurmey1835@gmail.com</i>
// 		</a>
// 		. Here are some of my small side projects. Click on the respective button to view its
// 		projects. ENJOY ! ^^
// 	</Typist>
// </div>

// {/* <FooterModule /> */}
// </div>
