import React, { useEffect, Fragment, useState } from 'react';
// import styles from './NavComponent.module.scss';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import navDatas from './NavDatas';

let styles = require('./NavComponent.module.scss');

// interface navDatas {
// 	link: string;
// 	name: string;
// }
const Nav = () => {
	// const [
	// 	nav,
	// 	setNav
	// ] = useState<navDatas[]>([]);

	const [
		checking,
		setChecking
	] = useState();

	useEffect(
		() => {
			shuffle(navDatas);
		},
		[
			checking
		]
	);

	function shuffle(array) {
		let currentIndex = array.length,
			randomIndex;

		// While there remain elements to shuffle...
		while (currentIndex != 0) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[
				array[currentIndex],
				array[randomIndex]
			] = [
				array[randomIndex],
				array[currentIndex]
			];
		}

		return array;
	}

	let stateis = false;

	const callFunc = () => {
		stateis = !stateis;
		setChecking(stateis);
	};

	const Links = navDatas.map((el, index) => (
		<Col key={index}>
			<Link to={el.link}>
				<Button id="names" className={`btn mb-3 ${styles.btn}`} variant="outline-dark">
					{el.name}
				</Button>
			</Link>
		</Col>
	));

	return (
		<Fragment>
			<Button
				onClick={callFunc}
				style={{
					position: 'absolute',
					top: 0,
					right: 0,
					marginTop: '50px',
					marginRight: '100px',
					width: '150px'
				}}>
				Shuffle
			</Button>
			<div>
				<Container>
					<Row className={styles.topic}>{Links}</Row>
				</Container>
			</div>
		</Fragment>
	);
};

export default Nav;
