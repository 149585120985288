import dictionary from './../../image/dictionary.png';

const djangoData = [{
    name: dictionary,
    title: 'Dictionary',
    status: 'Complete',
    text: 'A Django app to search for meaning of words..',
    demo: 'https://englishdictttt.herokuapp.com/',
    github: 'https://github.com/gyurmey/djangoEnglishDict'
}];

export default djangoData;