import Starwars from './../../image/starwar.png';
import covid from './../../image/pwa.png';
import bamberg from './../../image/bamberg.png';
import githubjobs from './../../image/githubjobs.png';
import bio from './../../image/bio.png';

const angularData = [{
        name: Starwars,
        title: 'Star Wars',
        status: 'Complete',
        text: 'An Angular app to display list of Star Wars movies and also link to its list of casting characters and the Movies they are in.',
        demo: 'https://vigorous-jang-93ab90.netlify.app/',
        github: 'https://github.com/gyurmey/P-Star-Wars'
    },
    {
        name: covid,
        title: 'Covid-19',
        status: 'Complete',
        text: 'A Mobile first Angular app to display the real-time data of covid-19 cases in Germany.',
        demo: 'https://covid-germany.netlify.app/',
        github: 'https://github.com/gyurmey/P-COVID-19-Dashboard'
    },
    {
        name: bamberg,
        title: 'University dashboard',
        status: 'WIP',
        text: 'University dashboard build using Angular.',
        demo: 'https://e-shop-bfdac.web.app/',
        github: 'https://github.com/gyurmey/P-uni-dashboard'
    },
    {
        name: bio,
        title: 'Bio',
        status: 'Complete',
        text: 'A simple site for introduction build using Angular.',
        demo: 'https://tenzin-intro.netlify.app/',
        github: 'https://github.com/gyurmey/P-bio'
    },
    {
        name: githubjobs,
        title: 'Github Jobs',
        status: 'Complete',
        text: 'An Angular app to display series of Github jobs.',
        demo: 'https://zealous-galileo-944d51.netlify.app/',
        github: 'https://github.com/gyurmey/P-Github-jobs'
    }
];

export default angularData;