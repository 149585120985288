import signal from './../../image/signal.png';

const nativeData = [{
    name: signal,
    title: 'Signal Clone',
    status: 'Complete',
    text: 'A Signal clone app build using React Native..',
    demo: 'https://signal-clone-9c8ac.web.app/',
    github: 'https://github.com/gyurmey/Signal-clone'
}];

export default nativeData;