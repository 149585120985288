import voting from './../../image/voting.png';
import zoom from './../../image/zoom.png';
import caes from './../../image/caes.png';

const nodeData = [{
        name: voting,
        title: 'University Voting web app',
        status: 'Complete',
        text: 'A secure E-voting prototype inspired from Neuchâtel protocol with return codes made with node and express.js.',
        demo: '',
        github: 'https://github.com/gyurmey/P-university-voting-web-app'
    },
    {
        name: caes,
        title: 'CAES',
        status: 'Complete',
        text: 'A Context aware experience sampling app.',
        demo: '',
        github: 'https://github.com/gyurmey/P-Context-Aware-Experience-Sampling'
    },
    {
        name: zoom,
        title: 'Zoom Clone',
        status: 'Complete',
        text: 'A Zoom clone app build using node and express.',
        demo: 'https://morning-tundra-08657.herokuapp.com/',
        github: 'https://github.com/gyurmey/Zoom-clone'
    }
];

export default nodeData;