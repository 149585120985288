import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import djangoDatas from './djangoDatas';
import { Link } from 'react-router-dom';
import FooterModule from '../../Components/footer/footer';
import { Button as Btn } from 'tenzin-ui-btn';
import styled from 'styled-components';
import { viewTransport } from '../../helper';

let styles = require('./django.module.scss');

const IconButton = styled(Btn)`
margin-top:0px;
@media (max-width: 700px){
	position: absolute;
	top: 0;
}
`;

const Django = () => {
	useEffect(() => {
		viewTransport();
	});

	window.onscroll = function() {
		viewTransport();
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	const djangoList = djangoDatas.map((el, index) => (
		<div className={styles.cards} key={index}>
			<Card className={styles.card}>
				<Card.Img src={el.name} alt={el.name} className={`mt-5 ${styles.cardImg}`} />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Card.Body className={styles.body}>
					<Card.Title>{el.title}</Card.Title>
					<Card.Subtitle className="mb-2 text-muted">Status : {el.status}</Card.Subtitle>
					<Card.Text>{el.text}</Card.Text>

					<br />
					<br />
					<br />
					<div className={styles.buttons}>
						<Button variant="outline-info" className={styles.demoBtn} href={el.demo} target="_blank">
							Demo
						</Button>
						{/* <Button variant="link" className={styles.githubBtn} href={el.github} target="_blank">
							Github
						</Button> */}
					</div>
				</Card.Body>
			</Card>
		</div>
	));

	return (
		<div className={styles.main}>
			<span className={styles.title}>
				{/* <i className={`bi bi-arrow-left ${styles.icon}`} /> */}
				<Link to="/Rocket">
					<IconButton>Back</IconButton>{' '}
				</Link>
			</span>

			<br />
			<br />
			<br />

			<h1 className={styles.heading}>
				{' '}
				Django
				<div>Django</div>
				<div>Django</div>
				<div>Django</div>
				<div>Django</div>
			</h1>
			<h4 className={styles.instruct}>Click on the Picture!</h4>
			<br />

			{djangoList}
			<br />
			<FooterModule />
			<br />
			<br />
			<br />
			<Button variant="light" onClick={scrollToTop} id="scrollToTop" className={styles.scrollTopBtn}>
				<i className="bi bi-arrow-up-circle" />
				<br />
				Top
			</Button>
		</div>
	);
};

export default Django;
