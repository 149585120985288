import airport from './../../image/airport.png';
import github from './../../image/github.png';
import bpmn from './../../image/bpmn.png';
import bank from './../../image/bank.png';
import employee from './../../image/employee.png';
import portfolio from './../../image/portfolio.png';
import cat from './../../image/cat.png';
import map from './../../image/map.png';
import youtube from './../../image/youtube.png';
import amazon from './../../image/amazon.png';
import mobx from './../../image/mobx.png';
import stepper from './../../image/stepper.png';
import spacex from './../../image/spacex.png';
import miniShop from './../../image/miniShop.png';
import bitcoin from './../../image/bitcoin.png';
import calculator from './../../image/calculator.png';
import chargemap from './../../image/chargemap.png';
import sorter from './../../image/sorter.png';
import bingo from './../../image/bingo.png';
import agepredictor from './../../image/age-predictor.png';
import genderPredictor from './../../image/genderPredictor.png';
import evilinsult from './../../image/evilinsult.png';

const reactData = [{
        name: portfolio,
        title: 'Portfolio',
        status: 'Complete',
        text: 'A Portfolio site build using React.',
        demo: 'https://tenzins-portfolio.netlify.app/',
        github: 'https://github.com/gyurmey/P-Portfolio-1.1'
    },
    {
        name: airport,
        title: 'Airport parking slot automation',
        status: 'WIP',
        text: 'A React app for parking slot management build using Redux..',
        demo: 'https://determined-kalam-124fbd.netlify.app/',
        github: 'https://github.com/gyurmey/P-Airport-Parking-Slot-Automation'
    },
    {
        name: map,
        title: 'Flight Tracker',
        status: 'WIP',
        text: "A React app to display flight's current location...",
        demo: 'https://clever-shannon-064ab4.netlify.app/',
        github: 'https://github.com/gyurmey/P-flight-tracker'
    },
    {
        name: cat,
        title: "Meower : Tinder for Cats - Cat's Companion",
        status: 'Complete',
        text: 'A React app to view different cat pictures and like or dislike them accordingly..',
        demo: 'https://happy-heyrovsky-7d665a.netlify.app/',
        github: 'https://github.com/gyurmey/P-Cat-Game'
    },
    {
        name: bank,
        title: 'Bank Info',
        status: 'WIP',
        text: 'A React app for managing bank details build using Redux..',
        demo: 'https://practical-kalam-e4ae22.netlify.app/',
        github: 'https://github.com/gyurmey/P-bank-info'
    },
    {
        name: github,
        title: 'Github Resume Generator',
        status: 'WIP',
        text: 'A React app to generate resume from github usernames..',
        demo: 'https://competent-hamilton-488d4f.netlify.app/',
        github: 'https://github.com/gyurmey/P-Github-Resume-Creator'
    },
    {
        name: bpmn,
        title: 'BPMN',
        status: 'WIP',
        text: 'A React app to view and edit BPMN 2.0 diagrams. If editing not working in some	case, then click on the sandbox link.',
        demo: 'https://silly-lamarr-5eb227.netlify.app/',
        github: 'https://codesandbox.io/s/reactjs-test-forked-h7b04?file=/src/App.js'
    },
    {
        name: mobx,
        title: 'MobX',
        status: 'WIP',
        text: 'A React app to filter the items using mobX.',
        demo: 'https://agitated-swirles-6590a3.netlify.app/',
        github: 'https://github.com/gyurmey/P-Mobx'
    },
    {
        name: employee,
        title: 'Salary supervisor',
        status: 'WIP',
        text: 'A React app to manage employee datas and salary..',
        demo: 'https://epic-lumiere-ef6826.netlify.app/',
        github: 'https://github.com/gyurmey/P-Salary-supervisor'
    },
    {
        name: amazon,
        title: 'Amazon Clone',
        status: 'Complete',
        text: 'A React app for Amazon..',
        demo: 'https://clone-c5043.web.app/',
        github: 'https://github.com/gyurmey/amazon-clone'
    },
    {
        name: youtube,
        title: 'Youtube Clone',
        status: 'Complete',
        text: 'A React app for Youtube..',
        demo: 'https://clone-e3d5b.web.app/',
        github: 'https://github.com/gyurmey/youtube-clone'
    },
    {
        name: stepper,
        title: 'React Stepper',
        status: 'WIP',
        text: 'A React app to display stepper functionality..',
        demo: 'https://focused-lamport-0709a8.netlify.app/',
        github: 'https://github.com/gyurmey/smartlab'
    },
    {
        name: spacex,
        title: 'SpaceX',
        status: 'WIP',
        text: 'A React app to display series of Rocket launches from spaceX build using GraphQL..',
        demo: 'https://stark-hamlet-48741.herokuapp.com/',
        github: 'https://github.com/gyurmey/SpaceX'
    },
    {
        name: miniShop,
        title: 'Mini-Shop',
        status: 'Complete',
        text: 'A Mini-Shop to Purchase Falcon Heavy or a pineapple built using Redux..',
        demo: 'https://gallant-sammet-74086c.netlify.app/',
        github: 'https://github.com/gyurmey/mini-shop'
    },
    {
        name: bitcoin,
        title: 'Bitcoin-Prize',
        status: 'Complete',
        text: 'A React app to display bitcoin prize rate of the past..',
        demo: 'https://priceless-jepsen-9e7398.netlify.app/',
        github: 'https://github.com/gyurmey/bitcoin-prize-php'
    },
    {
        name: calculator,
        title: 'Equation-Calculator',
        status: 'WIP',
        text: 'A React app to calculate equation and display in Graph',
        demo: 'https://sad-booth-f6cc3f.netlify.app/',
        github: 'https://github.com/gyurmey/Term-calculator'
    },
    {
        name: chargemap,
        title: 'Chargingpoint-Finder',
        status: 'Complete',
        text: 'A React app to find the charging point available near by',
        demo: 'https://compassionate-wilson-ef50b0.netlify.app/',
        github: 'https://github.com/gyurmey/charge-map'
    },
    {
        name: sorter,
        title: 'Simple-Sorter',
        status: 'Complete',
        text: 'A React app to sort the data accordingly',
        demo: 'https://quizzical-leakey-a78e4d.netlify.app/',
        github: 'https://github.com/gyurmey/HackerRank-Twilio-Sorting-func'
    },
    {
        name: bingo,
        title: 'Bingo App',
        status: 'Complete',
        text: 'A Bingo game app build with React using confetti and lottie',
        demo: 'https://nostalgic-jang-e268b0.netlify.app/',
        github: 'https://github.com/gyurmey/bingo'
    },
    {
        name: agepredictor,
        title: 'Age Predictor',
        status: 'Complete',
        text: 'A React app to predict the age of a person by his/her name, built with Redux-Saga',
        demo: 'https://musing-neumann-04c740.netlify.app/',
        github: 'https://github.com/gyurmey/Age-Predictor'
    },
    {
        name: genderPredictor,
        title: 'Gender Predictor',
        status: 'Complete',
        text: 'A React app to predict the gender of a person by his/her name, built with Rtk query',
        demo: 'https://fervent-lalande-fc6cc5.netlify.app/',
        github: 'https://github.com/gyurmey/Gender-Predictor'
    },
    {
        name: evilinsult,
        title: 'Evil Insult',
        status: 'Complete',
        text: 'A React app to insult yourself ^^ built with redux-thunk. If not working, enable CORS.',
        demo: 'https://wonderful-fermi-efc35d.netlify.app/',
        github: 'https://github.com/gyurmey/Evil-Insult'
    }
];

export default reactData;